import { createPopper } from '@popperjs/core'

export default {
  methods: {

    getCompanyLogo () {
      return process.env.VUE_APP_COMPANY_LOGO
    },

    getProfileLogo () {
      return process.env.VUE_APP_PROFILE_LOGO
    },

    generateId (length) {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'

      let result = ''

      const charactersLength = characters.length

      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
      }

      return result
    },

    isMobile () {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    },

    iconState (icon, isActive) {
      return `${icon}-${isActive ? 'fill' : 'line'}`
    },

    async getGeoLocation () {
      return new Promise((resolve, reject) => {
        const response = {
          title: 'Not Supported',
          message: 'Please update your browser',
          valid: false,
          position: {
            latitude: 0,
            longitude: 0
          }
        }

        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            function ({ coords }) {
              response.valid = true
              response.title = 'Location'
              response.message = 'Successful'
              response.position = coords
              resolve(response)
            },
            function (error) {
              response.valid = false
              switch (error.code) {
                case error.PERMISSION_DENIED:
                  response.title = 'Permission Denied'
                  response.message = 'Please allow your location first.'
                  break
                case error.POSITION_UNAVAILABLE:
                  response.title = 'Invalid Location'
                  response.message = 'Ayan mo ngay? nataw-taw kan san barok.'
                  break
                case error.TIMEOUT:
                  response.title = 'Request Time Out'
                  response.message = 'The request to get user location timed out.'
                  break
                case error.UNKNOWN_ERROR:
                  response.title = 'Unknown Error'
                  response.message = 'An unknown error occurred.'
                  break
              }

              reject(response)
            }
          )
        } else {
          reject(response)
        }
      })
    },

    scaleOnMobile (a, b) {
      if (this.isMobile()) {
        return Number(a.mobile) - Number(b.mobile)
      }
      return Number(b.index) - Number(a.index)
    },

    calculateDropPosition (dropDownList, component, { width }) {
      dropDownList.style.width = width
      const popper = createPopper(component.$refs.toggle, dropDownList, {
        // placement: this.placement,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1]
            }
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn ({ state }) {
              component.$el.classList.toggle(
                'drop-up',
                state.placement === 'top'
              )
            }
          }
        ]
      })
      return () => popper.destroy()
    }
  }
}
